import serverVars from 'server-vars';
import { IntlProvider } from 'dibs-react-intl';
import { hydrateRoot } from 'react-dom/client';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { getRelayEnvironment } from '../utils/relayModernEnvironment';
import storefrontRoot from '../dealerStorefront/storefrontRoot';
import StorefrontRefetch from '../dealerStorefront/StorefrontRefetch';
import { ReactRelayContext } from 'react-relay/legacy';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import { initializeDBL } from 'dibs-buyer-layout/src/entries/index';
import * as tracking from 'dibs-tracking';
import { ServerVarsContextProvider } from '../sharedComponents/ServerVarsContext';

const isPreview = serverVars.get('isPreview');

// dummy store for CurrencyProvider
let dblPromise = Promise.resolve({ store: createStore(() => ({ currency: { currency: null } })) });

if (!isPreview) {
    dblPromise = initializeDBL();
}

dblPromise.then(({ store }) => {
    const sellerData = serverVars.get('storefront.sellerData');
    const environment = getRelayEnvironment(serverVars.get('dbl.relayData'));
    const variables = serverVars.get('variables');
    const operation = createOperationDescriptor(getRequest(storefrontRoot), variables);
    const snapshot = environment.lookup(operation.fragment);

    environment.retain(operation);

    hydrateRoot(
        document.getElementById('js-root'),
        <IntlProvider locale={serverVars.get('locale')} messages={serverVars.get('messages')}>
            <ServerVarsContextProvider
                isMobile={serverVars.get('settings.isMobile')}
                isTablet={serverVars.get('settings.isTablet')}
                isDesktop={serverVars.get('settings.isDesktop')}
            >
                <Provider store={store}>
                    <ReactRelayContext.Provider
                        value={{
                            environment,
                            variables: snapshot.variables || {},
                        }}
                    >
                        <StorefrontRefetch
                            isPreview={isPreview}
                            initialVariables={variables}
                            viewer={snapshot.data.viewer}
                            sellerData={sellerData}
                        />
                    </ReactRelayContext.Provider>
                </Provider>
            </ServerVarsContextProvider>
        </IntlProvider>
    );
    tracking.trackPageview();
});
