/**
 * @generated SignedSource<<b7fb41dbaffa7bd24882f85bac50fd06>>
 * @relayHash 76856110e961b30a13c4443def3a3c70
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/476.0.0-2024-08-20T10:46:32.636Z/InitiateCallMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InitiateCallInput = {
  buyerCountryCode: number;
  buyerId: string;
  buyerName: string;
  buyerNumber: string;
  callbackPhoneId?: string | null;
  clientMutationId?: string | null;
  dealerId: string;
  itemId?: string | null;
  itemName?: string | null;
  phoneCallbackEnabled?: boolean | null;
  saveName?: boolean | null;
  savePhoneNumber?: boolean | null;
};
export type InitiateCallMutation$variables = {
  input: InitiateCallInput;
};
export type InitiateCallMutation$data = {
  readonly initiateCall: {
    readonly errors: ReadonlyArray<{
      readonly message: string | null;
    } | null> | null;
    readonly success: boolean;
  } | null;
};
export type InitiateCallMutation = {
  response: InitiateCallMutation$data;
  variables: InitiateCallMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InitiateCallPayload",
    "kind": "LinkedField",
    "name": "initiateCall",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GraphQLError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InitiateCallMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InitiateCallMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "app-buyer-misc/476.0.0-2024-08-20T10:46:32.636Z/InitiateCallMutation",
    "metadata": {},
    "name": "InitiateCallMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "53bc9d8798f7475aca3b0a9b059c924d";

export default node;
