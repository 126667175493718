import { trackEvent } from 'dibs-tracking';

export const locations = {
    DESIGN_SERIES: 'DESIGN_SERIES',
};

export const authLabels = {
    [locations.DESIGN_SERIES]: 'save search - design series',
};

export function trackFollowSearch({ label, isAdded }: { label: string; isAdded: boolean }): void {
    const category = 'search favoriting';
    if (isAdded) {
        trackEvent({
            category,
            action: 'search added',
            label,
        });
    } else {
        trackEvent({
            category,
            action: 'search removed',
            label,
        });
    }
}
