import Cookies from 'js-cookie';
import { localStorage } from 'dibs-browser-storage';
import { getEngagedItemsCookie } from 'dibs-cookie-jar';
import { defaultCookieSettings } from 'dibs-cookie-jar/exports/defaultCookieSettings';
import { COOKIE_KEYS } from 'dibs-cookie-jar/exports/cookieKeys';
import { Cookie } from 'dibs-cookie-jar/exports/types';

Cookies.default = defaultCookieSettings;

// Agreed max cookie size is 500 bytes, but some bytes reserved for cookie name
export const MAX_ENGAGED_ITEM_STRING_LENGTH = 500 - COOKIE_KEYS.ENGAGED_ITEMS.length;
// Using separator char that is not being encoded, it helps to keep cookie smaller
const SEPARATOR = '.';

const getEngagedItemsAsString = (cookie: Cookie = document.cookie): string => {
    const itemsFromCookie = getEngagedItemsCookie(cookie);
    if (itemsFromCookie) {
        return itemsFromCookie;
    }
    // Fallback to items from local storage if there's no cookie
    const itemsFromLocalStorage = localStorage.getItem(COOKIE_KEYS.ENGAGED_ITEMS);
    if (typeof itemsFromLocalStorage === 'string') {
        return itemsFromLocalStorage;
    }

    return '';
};

export const getEngagedItems = (cookie?: Cookie): string[] => {
    const engagedItemsAsString = getEngagedItemsAsString(cookie);
    return engagedItemsAsString ? engagedItemsAsString.split(SEPARATOR) : [];
};

const setEngagedItems = (items: string): void => {
    Cookies.set(COOKIE_KEYS.ENGAGED_ITEMS, items);
    localStorage.setItem(COOKIE_KEYS.ENGAGED_ITEMS, items);
};

const trunkToMaxCookieSize = (itemsAsString: string): string => {
    if (itemsAsString.length > MAX_ENGAGED_ITEM_STRING_LENGTH) {
        const arrayOfItems = itemsAsString.split(SEPARATOR);
        arrayOfItems.pop();
        return trunkToMaxCookieSize(arrayOfItems.join(SEPARATOR));
    }
    return itemsAsString;
};

export const addToEngagedItems = (itemId: string | null | undefined): void => {
    if (!itemId) {
        return;
    }
    const engagedItemsAsString = getEngagedItemsAsString();

    if (engagedItemsAsString.startsWith(itemId)) {
        // Item was engaged, but it is already the first item in the queue - do nothing
        return;
    }
    let newEngagedItemsAsString: string;
    if (!engagedItemsAsString) {
        newEngagedItemsAsString = itemId;
    } else if (!engagedItemsAsString.includes(itemId)) {
        // If item is engaged for the first time, just add it to the front of the queue
        newEngagedItemsAsString = `${itemId}${SEPARATOR}${engagedItemsAsString}`;
    } else {
        // If item had been already engaged, move it to the front of the queue
        newEngagedItemsAsString = [
            ...new Set([itemId, ...engagedItemsAsString.split(SEPARATOR)]),
        ].join(SEPARATOR);
    }

    newEngagedItemsAsString = trunkToMaxCookieSize(newEngagedItemsAsString);

    setEngagedItems(newEngagedItemsAsString);
};
